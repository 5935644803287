//button add new place
button.Addnew {
    width: 100%;
    background-color: #224aac;
    border-color: #224aac;
}

@media only screen and (min-width: 992px) and (max-width: 1247px) {
    .f-text {
        font-size: 0.7rem;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1247px) {
    .w-icon {
        width: 10px !important;
        height: 10px !important;
    }
}

// a:hover{
//     border-radius: 20px;
//     -webkit-transition: all 0.4s;
//     -moz-transition: all 0.4s;
//     transition: all 0.4s;
// }

// i:hover{
//     color: white;
// }
