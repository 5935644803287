.notification-badge {
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 45%;
    position: relative;
    top: -10px;
    right: 8px;
}

.map {
    height: 92% !important;
    width: 96.2% !important;
}

@media (max-width: 1024px) {
    .map {
        height: 92% !important;
        width: 93.5% !important;
    }
}

@media (max-width: 912px) {
    .map {
        height: 92% !important;
        width: 95.5% !important;
    }
}

@media (max-width: 820px) {
    .map {
        height: 92% !important;
        width: 94.5% !important;
    }
}

@media (max-width: 568px) {
    .map {
        height: 92% !important;
        width: 92.5% !important;
    }
}

@media (max-width: 430px) {
    .map {
        height: 92% !important;
        width: 90.5% !important;
    }
}

@media (max-width: 414px) {
    .map {
        height: 92% !important;
        width: 89.5% !important;
    }
}