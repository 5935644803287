.swiper-button-prev{
    color: lightgray;
    margin-left: 1rem;
}

.swiper-button-next{
    color: lightgray;
    margin-right: 3rem;
}
.swiper-slide swiper-slide-active{
    width: auto;
}