.read-more-less--more,
.read-more-less--less {
  color: #999;
}

.gradient {
  background-image: linear-gradient(180deg, rgb(34, 74, 172) 0%, rgb(17, 40, 95) 100%);
  height: 155px;
  border-radius: 5px;
}

h2.MT {
  padding-top: 3rem;
  padding-left: 2rem;
}

@media only screen and (max-width: 1439px) {
  h2.MT {
    padding-top: 3rem;
    padding-left: 1rem;
  }
}
@media only screen and (max-width: 991px) {
  h2.MT {
    padding-top: 3rem;
    padding-left: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  h2.MT {
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  h2.MT {
    font-size: 22px;
    padding-top: 1rem;
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 991px) {
  div.text-alignBT {
    text-align: left;
  }
}

@media only screen and (min-width: 375px) and (max-width: 576px) {
  div.text-alignBT {
    text-align: right;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.special_modal .modal-content {
  background-color: #f8f8f8;
  border-radius: 10;
}
.image-gallery-image {
  max-width: 100% !important;
  max-height: 77vh !important;
  min-height: 50vh !important;
  height: 50vh !important;
  width: 60vw !important;
  display: block;
}

@media only screen and (min-width: 375px) and (max-width: 991px) {
  .mb-category-text {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .t-detail-xs {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 616px) {
  .w-kanban-bin {
    width: 90% !important;
  }
}

.event-timeline-dot {
  position: initial;
  z-index: 9;
  font-size: 24px;
}

.bx {
  font-family: boxicons !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1 !important;
  display: inline-block;
  text-transform: none !important;
  speak: none !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
.bx-right-arrow-circle:before {
  content: "\eb1d";
}
img.style-img-guide {
  border-radius: 50%;
  object-fit: cover;
}
img.style-img-add-guide {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  object-fit: cover;
}
img.style-img-accept-guide {
  border-radius: 50%;
  object-fit: cover;
}

.font-style-18 {
  font-style: normal;
  font-size: 18px;
}
.text-alert{
  color: red;
}
// .color-c {
//   width: 100%;
//   background-color: white;
//   color: rgb(34, 74, 172) !important;
//   border-color: rgb(34, 74, 172);
// }
