// Page kabanGuide and Page kabannew
@media (min-width: 1200px) {
    .mr-modal {
        margin-right: 0.5rem !important;
    }
}

@media (max-width: 992px) {
    .mt-modal {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 1200px) {
    .pl-modal-guide {
        padding-left: 0rem !important;
    }
}
