.noti-icon .badge {
    left: 23px;
}
.mt-2 {
    margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid #eeeef5;
    height: 239px;
}
.dz-message {
    text-align: center;
    padding: 100px;
}
.fcYNFc {
    background-color: #ffffff !important;
}
.task-box {
    border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.fc-event .fc-content {
    padding: 5px;
    color: #fff;
}
.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

.border-0 {
    border: 0px !important;
}

.style-navbar {
    display: flex !important;
    justify-content: center !important;
    font-size: 18px !important;
    padding: 22px !important;
}

.mr-navbar {
    margin: 0px 50px;
}

.jc-navbar {
    justify-content: space-around;
}

.breadcrumb {
    background-color: transparent !important;
}

.cl-black {
    color: black;
}
.cl-light-black {
    color: #343a40;
}
.cl-blue {
    color: #2249ac;
}
.cl-dark-blue {
    color: #173865;
}
.cl-gray {
    color: #808080;
}
.cl-light-gray {
    color: #b9b9b9;
}
.font-style-14 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.font-style-16 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.font-style-18 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.font-style-24 {
    font-size: 1.5rem; //1.5rem = 24px
    font-style: normal;
    font-weight: 500;
}
.font-style-26 {
    font-family: Kanit;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
}

// img {
//   width: 150px;
//   height: 150px;
//   margin: 30px;
//   padding: 20px;
// }
// .btn {
//   margin: 15px;
//   padding: 5px;
// }

// [type="radio"] {
//   margin: 0;
//   padding: 0;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// input {
//   visibility: hidden;
// }

.textlinenumber {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

@media only screen and (min-width: 1200px) and (max-width: 1292px) {
    .t-button {
        width: 110% !important;
    }
}

@media only screen and (min-width: 1361px) and (max-width: 1698px) {
    .j-button {
        width: 140% !important;
        margin-left: -5rem !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1360px) {
    .j-button {
        width: 152% !important;
        margin-left: -5rem !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .time-period {
        display: block !important;
    }
    .icon-calendar {
        text-align: center;
        line-height: 2;
    }
    .detail-date {
        text-align: center;
        color: #224aac;
    }
    .align-col {
        justify-content: center;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .province {
        display: block !important;
    }
    .icon-marker {
        text-align: center;
        line-height: 2;
    }
    .detail-province {
        text-align: center;
        color: #224aac;
    }
    .align-col {
        justify-content: center;
    }
    .align-text {
        text-align: center !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1525px) {
    .tourist {
        display: block !important;
    }
    .icon-people {
        text-align: center;
        line-height: 2;
    }
    .detail-tourist {
        text-align: center;
        color: #224aac;
    }
    .align-col {
        justify-content: center;
    }
    .align-text-1 {
        text-align: center !important;
    }
}
//margin screen size 992px
@media (max-width: 992px) {
    .mt-24 {
        margin-top: 24px !important;
    }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
    .mt-10 {
        margin-top: 10px;
    }
    .mt-1r {
        margin-top: 1rem;
    }
}

.card-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
    border-radius: 5px !important ;
}
.hr-style {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border: 0 !important;
    border-top: 1px dashed #dfdfdf !important;
}
.hr-style-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border-radius: 41px !important;
    border-top: 1px solid #ffd91a !important;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    margin-top: 4px;
}
.custom-control-label::after {
    top: 0.32rem !important;
}

@media (min-width: 415px) and (max-width: 767px) {
    .mr-bt {
        margin-top: 1rem;
    }
}

.img-trip {
    margin-top: 1rem;
    border-radius: 5px;
    width: 100%;
    position: absolute;
    z-index: -1;
}

.cEfeTy {
    height: 100%;
    display: inline-block;
    padding: 15px;
    margin: 5px !important;
    vertical-align: top;
    background-color: rgb(65, 98, 182) !important;
    width: 30%;
    min-width: 483px;
    min-height: 500px !important;
    border-radius: 10px !important;
}
.board-style {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    overflow-x: scroll;
    position: relative;
    white-space: nowrap;
}
@media screen and (min-width: 1890px) {
    .board-style {
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width: 991px) {
    .resize-button {
        width: 100%;
    }
}
.dropdown-menu {
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.4) !important;
}
.img-lg {
    width: 10rem !important;
    height: 10rem;
}
.custom-file-label {
    top: 2rem !important;
    left: 0.8rem !important;
}
// .form-control {
//     color: #74788d !important;
// }
.gmaps {
    height: 350px !important;
}
.dropzone {
    margin-top: 1rem;
}
