.react-kanban-column {
    display: inline-block;
    padding: 15px;
    border-radius: 5px !important;
    background-color: #224aac !important;
    margin: 5px;
    vertical-align: top;
}


@media (max-width: 1824px) {
    .borad-width {
        width: 345px;
    }
}

@media (max-width: 1568px) {
    .borad-width {

        width: auto;
    }
}

@media (max-width: 1224px) {
    .borad-width {

        width: auto;
    }
}

.file-upload-card {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    background: #f9f9f9;
    border-radius: 5px;
    position: relative;
}

.file-icon {
    font-size: 40px;
}

.file-name {
    margin-top: 10px;
    font-size: 14px;
    word-break: break-all;
}

.btn-danger {
    position: absolute;
    top: 10px;
    right: 10px;
}

.stats-container {
    background-color: #f0f4f7;
    padding: 20px;
    border-radius: 10px;
}

.number {
    font-size: 2rem;
    font-weight: bold;
    color: #5a5a5a;
}

.label {
    font-size: 1rem;
    color: #7a7a7a;
}

.highlight {
    color: #007bff;
    /* Highlight color for the number 14 */
}

.border-right {
    border-right: 1px solid #e0e0e0;
}