.shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.toolbar {
  background-color: #224AAC;
  border-radius: 10px 10px 0 0;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "kanit", sans-serif !important;
  fill: #000000 !important;
}